<template>
  <PageListLayout @reset-filter="resetFilter">
    <template slot="header_right">
      <v-list-item three-line>
        <v-layout align-center>
          <v-img
            max-height="150"
            max-width="150"
            :src="BaoCaoPic"
            class="mr-4"
          ></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 black--text">
              BÁO CÁO HỆ THỐNG</v-list-item-title
            >
            <v-list-item-subtitle
              >Báo cáo khách hàng trọng tâm</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-layout>
      </v-list-item>
      <div class="d-flex">
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-btn class="mr-1 mt-12" icon v-on="on" @click="exportExcel()">
              <v-icon> mdi-file-export-outline </v-icon>
            </v-btn>
          </template>
          <span>Xuất Excel</span>
        </v-tooltip>
      </div>
    </template>
    <template slot="filter">
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.year"
          placeholder="Chọn năm"
          auto-select-first
          :items="years"
          outlined
          solo
          dense
          hide-details="auto"
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.month"
          :items="this.monthList"
          placeholder="Chọn tháng"
          item-text="name"
          item-value="id"
          solo
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.staff_id"
          :items="employers"
          placeholder="Nhân viên phụ trách"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.area_id"
          :items="areasData"
          placeholder="khu vực"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="desserts"
      :page.sync="page"
      :items-per-page=50
      :loading="isloading"
      hide-default-footer
      @change-page="changePage"
      classPaging="mt-2"
    ></CustomTable>
  </PageListLayout>
</template>
<script>
import BaoCaoPic from "../../../assets/images/baocao.svg";
import { monthList } from "@/constants/time";
import CustomerImportan from "@/api/report.js";
import { saveAs } from "file-saver";
import { generateArrayOfYears } from "@/utils/date.js";
import { getAllAreas } from "@/api/khuvuc";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
export default {
  data() {
    return {
      page: 1,
      monthList,
      BaoCaoPic,
      years: generateArrayOfYears(10),
      isloading: false,
      search: "",
      calories: "",
      items: [],
      data: {},
      headers: [
        {
          text: "Năm",
          value: "year",
          align: "start",
          filter: (value) => {
            if (!this.calories) return true;
            return value < parseInt(this.calories);
          },
          width: "50px",
        },
        { text: "Tháng", value: "month", width: "50px" },
        { text: "Nhân viên", value: "staff", width: "50px" },
        { text: "Khu vực", value: "area", width: "50px" },

        //
        {
          text: "Tổng doanh thu xử lí",
          value: "total_handle_revanue",
          width: "100px",
        },
        {
          text: "Tổng doanh thu xử lí từ nhóm KH trọng tâm",
          value: "total_handle_revanue_customer_important",
        },
        {
          text: "Kế hoạch doanh thu xử lí từ nhóm KH trọng tâm",
          value: "total_handle_plan_revanue_customer_important",
        },
        {
          text: "Trị giá mua phế liệu",
          value: "total_scrap_revanue",
        },

        {
          text: "Tổng giá trị mua phế liệu từ nhóm KH trọng tâm",
          value: "total_scrap_revanue_customer_important",
        },

        {
          text: "Mục tiêu giá trị mua phế liệu từ nhóm KH trọng tâm",
          value: "total_scrap_plan_revanue_customer_important",
          width: "100px",
        },
        {
          text: "Số KH trọng tâm đạt mục tiêu doanh thu/doanh số",
          value: "customer_impotan_pass_target",
          width: "150px",
        },
        {
          text: "Chi phí lobby (Chi phí quan hệ với chính quyền)",
          value: "cost_lobby",
          width: "150px",
        },
        {
          text: "Tổng chi phí chăm sóc KH",
          value: "total_customer_importan_costs",
          width: "100px",
        },
        {
          text: "Chi phí chăm sóc KH trọng tâm",
          value: "customer_important_cost",
          width: "100px",
        },
        {
          text: "Tổng số KH trọng tâm",
          value: "customer_impotan_total",
          width: "100px",
        },
        {
          text: "Tỷ trọng doanh thu xử lý từ nhóm khách hàng trọng tâm",
          value: "prob_revenue_customer_importan",
          width: "120px",
        },
        {
          text: "Tỷ lệ đạt kế hoạch doanh thu xử lý của khách hàng trọng tâm",
          value: "prob_pass_revenue_customer_importan",
          width: "120px",
        },
        {
          text: "Tỷ trọng giá trị phế liệu thu gom từ khách hàng trọng tâm",
          value: "prob_scrap_revenue_customer_importan",
          width: "120px",
        },
        {
          text: "Tỷ lệ đạt kế hoạch giá trị phế liệu thu gom từ khách hàng trọng tâm",
          value: "prob_pass_scrap_revenue_customer_importan",
          width: "120px",
        },
        {
          text: "Tỷ lệ KH trọng tâm đạt mục tiêu doanh thu/doanh số",
          value: "prob_target_customer_importan",
          width: "100px",
        },
        {
          text: "Tỷ lệ chi phí chăm sóc khách hàng trọng tâm",
          value: "prob_cost_customer_importan",
          width: "100px",
        },
        {
          text: "Tỷ lệ chi phí chăm sóc khách hàng trọng tâm trên tổng chi phí giao dịch",
          value: "prob_total_cost_customer_importan",
          width: "120px",
        },
      ],
      data_render: [],
      desserts: [],
      areasData: [],
      employers: [],
      formSearch: {
        year: new Date().getFullYear(),
        month: "",
        staff_id: [],
        area_id: [],
      },
    };
  },
  computed: {},
  created() {
    this.isloading = true;
    this.getAllEmployee();
    this.getAllArea();
    this.getData();
    this.isloading = false;
  },
  methods: {
    changePage(val) {
      this.page = val;
    },
    async getData() {
      try {
        this.isloading = true;
        const res = await CustomerImportan.getReportCustomerImportan(
          this.formSearch
        );
        this.data = res.data;
        this.desserts = [];
        this.loadData(this.data);
        this.isloading = false;
      } catch (error) {
        this.$toast.error("lỗi" + " " + error, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          message: error,
        });
      }
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.employers = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllArea() {
      let data = await getAllAreas();
      this.areasData = data;
    },
    loadData(target) {
      target.forEach((item) => {
        this.desserts.push({
          year: item.year,
          month: item.month,
          staff: item.staff,
          area: item.area,
          total_handle_revanue: new Intl.NumberFormat().format(
            item.total_handle_revanue
          ),
          total_handle_revanue_customer_important:
            new Intl.NumberFormat().format(
              item.total_handle_revanue_customer_important
            ),
          total_handle_plan_revanue_customer_important:
            new Intl.NumberFormat().format(
              item.total_handle_plan_revanue_customer_important
            ),
          total_scrap_revanue: new Intl.NumberFormat().format(
            item.total_scrap_revanue
          ),
          total_scrap_revanue_customer_important:
            new Intl.NumberFormat().format(
              item.total_scrap_revanue_customer_important
            ),
          total_scrap_plan_revanue_customer_important:
            new Intl.NumberFormat().format(
              item.total_scrap_plan_revanue_customer_important
            ),
          customer_impotan_pass_target: item.customer_impotan_pass_target,
          cost_lobby: item.cost_lobby,
          total_customer_importan_costs: item.total_customer_importan_costs,
          customer_important_cost: item.customer_important_cost,
          customer_impotan_total: item.customer_impotan_total,
          //
          prob_revenue_customer_importan: item.prob_revenue_customer_importan,
          prob_pass_revenue_customer_importan:
            item.prob_pass_revenue_customer_importan,
          prob_scrap_revenue_customer_importan:
            item.prob_scrap_revenue_customer_importan,
          prob_pass_scrap_revenue_customer_importan:
            item.prob_pass_scrap_revenue_customer_importan,
          prob_target_customer_importan: item.prob_target_customer_importan,
          prob_cost_customer_importan: item.prob_cost_customer_importan,
          prob_total_cost_customer_importan:
            item.prob_total_cost_customer_importan,
          //
        });
      });
    },
    async exportExcel() {
      try {
        this.loadingExport = true;
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
        let newdate = day + "/" + month + "/" + year;
        const res = await CustomerImportan.exportCustomerImportan(
          this.formSearch
        );
        if (res && res.isDone) {
          saveAs(
            new Blob([res.data]),
            "BaoCaoThiPhan" + "-" + newdate + ".xlsx"
          );
          this.$toast.success("Xuất báo cáo thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        }
      } catch (error) {
        this.$toast.error("lỗi" + " " + error, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          message: error,
        });
      }
    },
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
  },
  watch: {
    formSearch: {
      handler: function (newValue) {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style>
.app {
  margin-top: 10px;
}
</style>